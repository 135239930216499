.title-text {
  font-family: "Arial", sans-serif;
  font-size: 24px;
  color: #ed8f6f; /* vibrant blue */
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 80px;
}

.intro-text {
  font-family: "Arial", sans-serif;
  font-size: 22px;
  line-height: 1.6;
  color: #ffffff; /* dark navy blue */
  padding: 20px;
  margin: 0 auto;
  max-width: 70%;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  text-align: justify;
}

.intro-text::first-letter {
  font-size: 32px;
  color: #ed8f6f; /* vibrant blue */
}

.intro-text::before {
  content: "» ";
  font-size: 24px;
  color: #ed8f6f; /* vibrant blue */
}

.intro-text::after {
  content: " «";
  font-size: 24px;
  color: #ed8f6f; /* vibrant blue */
}

@media screen and (max-width: 768px) {
  /* Adjust this to suit the breakpoint you want */
  .intro-text {
    padding: 10px;
    max-width: 98%;
    font-size: 21px;
  }
}
