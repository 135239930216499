.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  margin-top: 0px;
  min-height: 200px;
  padding-bottom: 0px; /* Adjust this value as needed */
}

.footer-container a:hover {
  color: #555;
}

.address-container,
.contact-container,
.legal-and-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px; /* Decrease this value as needed */
  line-height: 35px;
  color: #555;
  font-size: 18px;
  text-align: center;
}

.social-icons {
  display: flex;
  flex-direction: row;
  margin-top: 10px; /* Increase this value as needed */
}

.social-icons a {
  margin: 0 10px;
  color: #555;
}

.legal-container a {
  margin-right: 10px;
  text-decoration: none;
  color: #555;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logo-container img {
  max-height: 200px; /* adjust as necessary */
  max-width: 200px; /* adjust as necessary */
  object-fit: contain; /* this makes sure your image scales properly */
}

/* For larger screens */
@media (min-width: 868px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    padding-bottom: 0; /* Remove the padding at the bottom for larger screens */
  }

  .address-container,
  .contact-container,
  .legal-and-social {
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 0px; /* Restore the original margin-top for larger screens */
  }

  .legal-container {
    text-align: left; /* Align legal container text to the right */
  }
  .logo-container {
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
}
