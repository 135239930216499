.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 70%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.close-icon {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 30px;
  cursor: pointer;
}
