.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

/* For viewports up to 768px wide */
@media (max-width: 768px) {
  .main-navigation__logo img {
    height: auto;
    width: 160px; /* Set to a fixed pixel value */
    float: right;
  }
}

/* For viewports between 769px and 868px wide */
@media (min-width: 769px) and (max-width: 968px) {
  .main-navigation__logo img {
    height: auto;
    width: 160px; /* Adjust this value as needed */
    float: right;
  }
}

/* For viewports wider than 968px */
@media (min-width: 968px) {
  .main-navigation__logo img {
    margin-top: 10px;
    height: auto;
    width: 190px; /* Set to 'auto' to maintain aspect ratio */
    margin-left: 30px;
  }

  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
