.services-list {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 70px auto;
}

.service-item {
  width: 27%;
  margin: 1em;
  height: auto;
  text-align: center;
  background-color: #ef6c00;
  color: #fff;
  padding: 0.7em;
  border-radius: 15px;
}

.title {
  text-align: center;
  margin-bottom: 0.4em;
  width: 100%;
  font-size: 2em;
  color: #ed8f6f;
}

.service-item img {
  width: 100%;
  height: auto;
}

.service-item h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin: 1em 0;
  color: #fff;
}

.service-item p {
  font-size: 1.3em; /* Increase font size */
  padding: 1em 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.more-info-button {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  color: #fff;
  background-color: #4a148c;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: all 0.3s ease 0s;
}

.more-info-button:hover {
  background-color: #f8aa00;
}

@media (max-width: 768px) {
  .services-list {
    flex-direction: column;
    align-items: center;
    margin: 40px auto;
  }

  .service-item {
    width: 90%;
  }

  .title {
    font-size: 1.7em;
    padding: 0 1em;
  }
}

@media (max-width: 968px) {
  .service-item h2 {
    font-size: 1.4em;
  }
  .service-item p {
    font-size: 1.2em;
  }
}
