.about-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 70px auto;
  max-width: 960px;
  padding: 2em 0;
}

.about-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1em 0;
  padding: 2em;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.5s ease-in-out;
}

.section-title {
  font-size: 2em;
  font-weight: 700;
  color: #333;
  margin-bottom: 0.75em;
}

.section-content {
  font-size: 1.25em;
  line-height: 1.6;
  color: #555;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media (max-width: 768px) {
  .about-container {
    margin: 50px auto;
  }
  .about-section {
    width: 95%;
    margin-top: 40px 0;
    padding: 1em;
  }
  .section-title {
    font-size: 1.5em;
  }
  .section-content {
    padding: 0;
  }
}
