.contact-page {
  width: 80%;
  margin: 80px auto;
  padding: 20px;
}

.contact-header {
  text-align: center;
  color: #ed8f6f;
  font-size: 30px; /* Adjust as needed */
  margin: 0px 0;
}

.contact-info {
  display: flex;
  flex-direction: row; /* Explicitly set for larger screens */
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.info-card {
  flex: 2;
  margin: 10px;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.info-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.info-item {
  flex: 1;
  margin: 10px;
  padding: 20px;
  background-color: #f1f1f1;
  border-radius: 10px;
}

.info-header {
  margin-bottom: 10px;
  color: #333;
  font-size: 22px; /* Adjust as needed */
}

.info-text {
  color: #555;
  font-size: 20px; /* Adjust as needed */
}

.map-container {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
}

.map-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
}

@media screen and (max-width: 768px) {
  /* Adjust this to suit the breakpoint you want */
  .contact-page {
    width: 100%;
  }

  .contact-info {
    flex-direction: column; /* Stack items vertically for small screens */
  }

  /* Optional: To make sure .info-card is always on top */
  .info-card {
    order: -1;
  }
  .info-item {
    padding: 10px;
  }
  .map-container iframe {
    padding: 0;
  }
}
