.item-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 30px 0;
}

.item {
  display: flex;
  gap: 40px;
  align-items: center;
  border-top: 1px solid #ccc;
  padding: 40px;
  max-width: 90%;
  margin: 0 auto;
}

.item-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item-image {
  width: 80%;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;
}

.item-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #fff;
  flex: 1;
}

.item-title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.item-description {
  margin: 0;
  font-size: 20px;
  line-height: 35px;
}

.item-prices {
  display: flex;
  gap: 10px;
  align-self: flex-end;
  margin-top: 20px;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .item {
    flex-direction: column;
    max-width: 100%;
    padding: 10px 0px;
  }

  .item-image-container,
  .item-info {
    flex-basis: 100%;
  }

  .item-image {
    width: 420px; /* Increase image width on small screens */
    height: 300px;
  }

  .item-info {
    color: #fff;
    order: -1;
    padding: 10px 0; /* Add vertical padding to space out the info and image */
  }
}
