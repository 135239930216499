/* DatenSchutzPage.css */

.datenschutz {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  color: #f2f2f2; /* this is a light grey color */
  font-family: Arial, sans-serif;
  margin: 70px auto;
}

.datenschutz h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #ed8f6f; /* this is a gold color */
}

.info-section {
  width: 80%;
  margin-bottom: 30px;
}

.info-section p {
  font-size: 1.2em;
  line-height: 1.5;
  color: #f2f2f2; /* this is a light grey color */
}

@media (max-width: 768px) {
  .info-section {
    width: 100%;
  }
  .datenschutz h2 {
    font-size: 2em;
  }
}
