.title-text {
  font-family: "Arial", sans-serif;
  font-size: 26px;
  color: #ed8f6f; /* vibrant blue */
  text-align: center;
  padding: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 100px;
}

.intro-text {
  font-family: "Arial", sans-serif;
  font-size: 22px;
  line-height: 1.6;
  color: #ffffff; /* dark navy blue */
  padding: 20px;
  margin: 0 auto;
  max-width: 70%;
  border-radius: 10px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  text-align: justify;
}

.intro-text::first-letter {
  font-size: 32px;
  color: #ed8f6f; /* vibrant blue */
}

.intro-text::before {
  content: "» ";
  font-size: 24px;
  color: #ed8f6f; /* vibrant blue */
}

.intro-text::after {
  content: " «";
  font-size: 24px;
  color: #ed8f6f; /* vibrant blue */
}

.button-container {
  display: flex;
  justify-content: space-between;
  margin: 50px auto;
  width: 80%;
  flex-wrap: nowrap;
  height: 80px;
}

.option-button {
  flex-basis: calc(33.33% - 40px);
  padding: 15px 0;
  border: none;
  border-radius: 5px;
  background-color: #4a148c;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option-button:hover {
  background-color: #f8aa00;
}

.option-button.active {
  background-color: #f8aa00;
}

@media screen and (max-width: 768px) {
  /* Adjust this to suit the breakpoint you want */
  .intro-text {
    padding: 10px;
    max-width: 90%;
  }
  .button-container {
    width: 98%;
    gap: 5px;
    margin: 30px auto 0px auto;
  }
  .option-button {
    flex-basis: calc(33.33% - 5px);
    font-size: 16px;
    font-weight: bold;
  }
  .title-text {
    padding: 10px;
  }
}
