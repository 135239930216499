.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70vw;
  height: 500px;
  margin: 120px auto 0 auto;
  overflow: hidden;
}

.carousel__image {
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 100%;
  opacity: 0;
  transition: opacity 0.5s linear;
}

.carousel__image.activeSlide {
  opacity: 1;
  transition: opacity 1s;
}

.carousel__button {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.carousel__button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.carousel__button--left {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.carousel__button--right {
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

@media screen and (max-width: 600px) {
  .carousel {
    width: 95vw;
    height: 300px;
    margin: 40px auto 0 auto;
  }
  .carousel__button {
    padding: 3px;
  }
}
