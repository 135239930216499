.contact-us-section {
  position: relative;
  max-width: 90%;
  height: 40vh;
  margin: 70px auto;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-container {
  cursor: pointer;
  width: 92%;
  height: 335px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card {
  background-color: transparent;
  width: 90%;
  height: 330px;
  perspective: 900px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transform-style: preserve-3d;
}

@keyframes stay {
  to {
    transform: rotateY(180deg);
  }
}

@keyframes return {
  to {
    transform: rotateY(0deg);
  }
}

.flip-card-container:hover .flip-card-inner {
  animation: stay 0.8s forwards;
}

.flip-card-container:not(:hover) .flip-card-inner {
  animation: return 0.8s forwards 0.3s; /* 0.3s is the delay */
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
}

.flip-card-front {
  background-color: #121416;
  color: white;
  font-size: 1.5em;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.flip-card-back {
  color: white;
  transform: rotateY(180deg);
  font-size: 1.5em;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(45deg, #ef6c00, rgba(255, 255, 255, 0.7));
}

.flip-card-back__title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.contact-us-section a {
  display: inline-block;
  color: #fff;
  background-color: #4a148c;
  padding: 10px 20px;
  margin-top: 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-us-section a:hover {
  background-color: #4a148c; /* You might want to change this to another color to make the hover effect noticeable */
}

@media (max-width: 768px) {
  .contact-us-section {
    margin: 50px 10px;
    padding: 0;
    max-width: 100%;
    height: auto;
  }

  .flip-card-container {
    width: 100%;
    height: 345px; /* Adjust if needed for mobile */
  }

  .flip-card {
    width: 100%;
    height: 340px;
  }

  .flip-card-front {
    font-size: 1.4em;
    padding: 10px;
  }

  .flip-card-back {
    font-size: 1.5em;
  }

  .flip-card__link {
    font-size: 0.9em;
  }
}
