.vehicle-types-section {
  color: white;
  padding: 2em 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 30px auto;
  width: 95%;
}

.vehicle-types-section .title {
  text-align: center;
  margin-bottom: 1em;
  width: 100%;
  font-size: 2em;
  color: #ed8f6f;
}

.vehicle-item {
  flex: 1 0 20%;
  margin: 1em;
  text-align: center;
  box-sizing: border-box;
}

.vehicle-item img {
  max-width: 100%;
  height: auto;
}

.vehicle-type {
  margin-top: 1em;
  margin-bottom: 0.5em;
  font-size: 1.5em;
  color: #ed8f6f;
}

.vehicle-description {
  margin-bottom: 0;
  font-size: 1.3em;
  text-align: justify;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .vehicle-types-section {
    margin: 0px auto;
    width: 100%;
  }
  .vehicle-item {
    flex: 1 0 45%;
  }

  .vehicle-types-section .title {
    font-size: 1.7em;
    padding: 0;
  }
}
