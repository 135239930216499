/* WelcomeText.css */

.welcomeContainer {
  text-align: center;

  border-radius: 5px;
  margin: 70px;
}

.welcomeTitle {
  color: #ed8f6f;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.welcomeText {
  color: white;
  font-size: 1.6em;
  line-height: 1.5;
  text-align: justify;
}

.highlightText {
  font-weight: bold;
  color: #ed8f6f;
}

@media screen and (max-width: 600px) {
  .welcomeContainer {
    margin: 10px 15px 40px 15px;
  }
  .welcomeTitle {
    font-size: 1.9em;
  }
  .welcomeText {
    font-size: 1.4em;
  }
}
