.leistungen-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  margin: 90px 50px;
}

.leistungen-card {
  width: 48%;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  position: relative;
  margin: 5px 20px;
}

.leistungen-card:hover {
  transform: scale(1.03);
}

.leistungen-card:nth-child(even) {
  align-self: flex-end;
}

.leistungen-image {
  width: 100%;
  height: 400px;
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: cover;
}

.leistungen-text {
  margin-top: 10px;
}

.leistungen-title {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.leistungen-description {
  font-size: 20px;
  color: #555;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .leistungen-container {
    width: 100%;
    align-items: center;
    margin: 0px 0;
  }

  .leistungen-card {
    width: 95%;
    margin: 10px;
    padding: 10px;
  }

  .leistungen-card:nth-child(even) {
    align-self: center;
  }
  .leistungen-image {
    height: 300px;
  }
}
